import React, { useEffect, useMemo } from 'react';
import Resizer from 'react-image-file-resizer';
import { useDropzone } from 'react-dropzone';
import { useRecoilState } from 'recoil';
import { upsertAutomationFormState } from '../../../state';

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      100,
      0,
      (uri) => resolve(uri),
      'file'
    );
  });

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderstyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  marginTop: '16px'
};

const focusedStyle = {
  borderColor: '#2196f3',
  cursor: 'pointer'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

export default function ImageUpload() {
  const [formData, setFormData] = useRecoilState(upsertAutomationFormState);
  // console.log(formData);
  const { files } = formData;
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        'image/*': []
      },
      onDrop: (acceptedFiles) => {
        // console.log(acceptedFiles);
        async function dropAndLoad() {
          const promises = acceptedFiles.map((file) => {
            return resizeFile(file).then((image) => {
              console.log('IMAGE: ', image);
              console.log('FILE: ', file);
              console.log(
                'SMALLER size: ',
                (image.size / (1024 * 1024)).toFixed(2)
              );
              console.log(
                'size BEFORE: ',
                (file.size / (1024 * 1024)).toFixed(2)
              );
              return Object.assign(image, {
                file: image,
                preview: URL.createObjectURL(image)
              });
            });
          });
          const localFiles = await Promise.all(promises);
          console.log(localFiles);
          setFormData({ ...formData, files: files.concat(localFiles) });
        }
        dropAndLoad();
        // setFormData({ ...formData, files: localFiles });
      }
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  // console.log('FILES: ', files);
  const thumbs = files.map((file, i) => (
    <div style={thumb} key={`file-${i}`}>
      <div style={thumbInner}>
        {/* {console.log('RENDER FILE: ', file)} */}
        <img
          alt="No"
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log('render image upload');
  return (
    <section className="container">
      <div height="400px" {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag and drop file or click to upload</p>
      </div>
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
}
