import Parse from '../parse';

// Parse.User.become('r:d5a8e8b323e55b6edaafaad77d3603af');

class Account extends Parse.Object {
  constructor(attributes) {
    // Pass the ClassName to the Parse.Object constructor
    super('Account', attributes);
    // All other initialization
  }

  static currentAccount() {
    const currentUser = Parse.User.current();
    return currentUser.get('account');
  }
}

export default Account;
