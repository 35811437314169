const Public = {
  AutomationTypes: {
    CheckoutStarted: {
      value: 'checkoutStarted',
      label: 'Checkout Started (Abandoned Cart)'
    },
    PlacedOrder: {
      value: 'placedOrder',
      label: 'Placed Order'
    },
    OrderFulfilled: {
      value: 'orderFulfilled',
      label: 'Order Fulfilled'
    },
    CustomerWinback: {
      value: 'customerWinback',
      label: 'Customer Winback'
    },
    NewSubscriber: {
      value: 'newSubscriber',
      label: 'New Subscriber Confirmed (Opted In Via Text)'
    }
  }
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const dataState = {
  initial: 'initial',
  loading: 'loading',
  loaded: 'loaded',
  error: 'error'
};

export const optionText = {
  checkoutStarted:
    '{StoreName}: Hey {FirstName}, we noticed you left something in your cart! Would you like to complete your purchase? {AbandonedCheckoutUrl}',
  customerWinback:
    '{StoreName}: 👋 Hey you! Been a while. Just wanted to check in! Have your 👀 on something? Shop here: {StoreUrl}',
  placedOrder:
    "{StoreName}: Hi {FirstName}, we've received your order! We're already working on it! Stay tuned for more updates here.",
  newSubscriber:
    "{StoreName}: Hi {FirstName}, just wanted you to have my number so you can ask any questions you might have. I also wanted to offer you 10% off your next purchase with us. Let me know if you'd like a promo code :)",
  orderFulfilled:
    '{StoreName}: Hi {FirstName}, just wanted to let you know your order has been shipped and you can track your order here: {TrackingUrls}'
};

export default Public;
