import React, { useEffect } from 'react';

import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import Parse from '../../../parse';
import ImageUpload from './ImageUpload';
import { Automation, Media } from '../../../models';
import {
  automationsState,
  upsertAutomationFormState,
  upsertAutomationFormMessageValue,
  upsertAutomationFormLabelValue
} from '../../../state';
import utils, { capitalizeFirstLetter } from '../../../utils';
import makeid from '../../../utils/makeId';
import replaceItemAtIndex from '../../../utils/replaceItemAtIndex';

// const defaultText =
//   'Hey {FirstName}, we noticed you left something in your cart! Would you like to complete your purchase? {AbandonedCheckoutUrl}';

const thumb = {
  display: 'inline-flex',
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  borderRadius: 5,
  display: 'block',
  width: 'auto',
  height: '100%',
  maxWidth: '130px',
  maxHeight: '210px'
};

const textVars = [
  '{StoreName}',
  '{StoreUrl}',
  '{FirstName}',
  '{LastName}',
  '{DiscountCode}',
  '{AbandonedCheckoutUrl}'
];

const automationTypes = Object.keys(utils.AutomationTypes).map(
  (key) => utils.AutomationTypes[key]
);

export default function UpsertAutomation() {
  const textAreaRef = React.createRef();
  const navigate = useNavigate();

  let { automationId } = useParams();
  const location = useLocation();

  const path = location.pathname.split('/');
  let upsertType = 'new';
  if (path.length > 3) {
    upsertType = path[2];
  }

  const [automations, setAutomations] = useRecoilState(automationsState);
  const [formData, setFormData] = useRecoilState(upsertAutomationFormState);
  const formMessageValue = useRecoilValue(upsertAutomationFormMessageValue);
  const formLabelValue = useRecoilValue(upsertAutomationFormLabelValue);
  // console.log(formData);
  const { files } = formData;
  let defaultValue;

  useEffect(() => {
    const fetchAutomation = async () => {
      console.log('FETCHING AUTOMATION');
      if (!automationId) {
        return;
      }
      const automation = await Automation.createWithoutData(
        automationId
      ).fetchWithInclude(['media']);
      // setTest({ ...test, me: 'kevin' });
      console.log(automation.id);
      const media = automation.get('media') || [];
      console.log('MEDIA: ', media);
      const files = media.map((file) => ({
        preview: file.get('file').url(),
        mediaId: file.id
      }));

      const data = {
        automationType: automation.get('automationType'),
        message: automation.get('message'),
        label: automation.get('label'),
        delayValue: automation.get('delayValue') ?? '',
        delayUnits: automation.get('delayUnits') ?? '',
        automationId: automation.id,
        upsertType,
        files
      };

      console.log(data);
      setFormData({ ...formData, ...data });
    };
    fetchAutomation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function addAutomation(formData) {
    // console.log(formData);
    const { files, isLoading, automationId, upsertType, ...data } = formData;

    console.log(files, isLoading, automationId, upsertType);
    files.forEach((file) => console.log(file.media));
    const media = files.map((file) => {
      if (file.mediaId) {
        return Media.createWithoutData(file.mediaId);
      }
      return new Media({
        file: new Parse.File(makeid('10'), file.file, 'image/png')
      });
    });
    // console.log(media);
    // return;
    const account = Parse.User.current().get('account');
    const saveData = {
      ...data,
      label: formLabelValue,
      message: formMessageValue,
      media,
      account,
      enabled: true
    };

    console.log(saveData);

    const automation = new Automation();
    console.log(automation);
    if (automationId) {
      console.log('THIS IS EDIT');
      console.log('automationID: ', formData.automationId);
      const oldAutomation = Automation.createWithoutData(automationId);
      await oldAutomation.save({ active: false });
      // automation.id = automationId;
    }
    console.log(automation);
    console.log('SAVE DATA: ', saveData);
    console.log('NOW ++++ automation id: ', automation.id);
    console.log(automation);
    await automation.save(saveData);
    console.log('Automation saved');
    console.log(automation);
    if (automationId) {
      const index = automations.data.findIndex(
        (auto) => auto.id === automationId
      );
      const newList = replaceItemAtIndex(automations.data, index, automation);
      setAutomations({ ...automations, data: newList });
    } else {
      const newList = [...automations.data, automation];
      setAutomations({ ...automations, data: newList });
    }
    setFormData({ ...formData, isLoading: false });
    navigate(`/automations/edit/${automation.id}`, { replace: true });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormData({ ...formData, isLoading: true });
    addAutomation(formData);
  };

  const insertText = (value) => {
    const node = textAreaRef.current;
    // const textAreaRef = React.createRef();
    console.log('NODE: ', node.selectionStart);

    const cursorPosition = node.selectionStart;
    const textBeforeCursorPosition = formData.message.substring(
      0,
      cursorPosition
    );
    const textAfterCursorPosition = formData.message.substring(
      cursorPosition,
      formData.message.length
    );
    const message = textBeforeCursorPosition + value + textAfterCursorPosition;
    setFormData({ ...formData, message });
  };

  const options = automationTypes
    .map((automation) => {
      const values = {
        key: automation.value,
        label: automation.label,
        value: automation.value
      };
      // console.log(formData.automationType);
      if (automation.value === formData.automationType) {
        defaultValue = values;
      }
      return values;
    })
    .filter(
      (option) => option.key !== utils.AutomationTypes.OrderFulfilled.value
    );
  console.log('Options: ', options);

  console.log('Auto type: ', formData.automationType);
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Flex width="full">
        <Box p={2}>
          <Box
            p="5"
            borderWidth="1px"
            borderColor={'transparent'}
            // borderRadius="lg"
          >
            <Heading>Create Automation</Heading>
          </Box>
          <Box mt={6} textAlign="left">
            <form onSubmit={handleSubmit}>
              <Box
                p="5"
                borderWidth="1px"
                bg="white"
                // borderRadius="lg"
              >
                {console.log(
                  'right before selected: ',
                  formData.automationType
                )}
                <FormControl>
                  <FormLabel>Automation Type</FormLabel>
                  <FormHelperText>
                    Select the segment of customers you want to receive this
                    text automation
                  </FormHelperText>
                  <Select
                    chakraStyles={{
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        bg: 'white',
                        px: 2,
                        cursor: 'inherit'
                      }),
                      container: (provided) => ({
                        ...provided,
                        backgroundColor: 'white'
                      }),
                      indicatorSeparator: (provided) => ({
                        ...provided,
                        display: 'none'
                      })
                    }}
                    placeholder="Select option"
                    selectedOptionColor="cyan"
                    value={defaultValue}
                    options={options}
                    onChange={(e) => {
                      console.log(e);
                      return setFormData({
                        ...formData,
                        automationType: e.value
                      });
                    }}
                  />
                  <FormHelperText>This helps</FormHelperText>
                </FormControl>
              </Box>
              <Box p="5" mt="5" borderWidth="1px" bg="white">
                <FormControl>
                  <FormLabel>Automation Name</FormLabel>
                  <FormHelperText>
                    Enter a automation name to track results. Abandoned Cart
                  </FormHelperText>
                  <Input
                    onChange={(e) =>
                      setFormData({ ...formData, label: e.target.value })
                    }
                    value={formLabelValue}
                    bg="white"
                    type="text"
                    placeholder="Automation Name"
                  />
                </FormControl>
              </Box>
              <Box p="5" mt="5" borderWidth="1px" bg="white">
                <FormControl>
                  <FormLabel>Delay</FormLabel>
                  <FormHelperText>Helper text</FormHelperText>

                  <Grid templateColumns="repeat(5, 1fr)" gap={6}>
                    <GridItem colSpan={3} w="100%">
                      <Input
                        type="number"
                        onChange={(e) => {
                          // if (isNumeric(e.target.value)) {
                          console.log(e.target.value);
                          console.log(typeof e.target.value);
                          console.log(Number(e.target.value));

                          setFormData({
                            ...formData,
                            delayValue: Number(e.target.value)
                          });
                          // }
                        }}
                        value={formData.delayValue}
                        bg="white"
                        placeholder="Delay time"
                        textAlign="right"
                      />
                    </GridItem>
                    <GridItem colSpan={2} w="100%">
                      <Select
                        chakraStyles={{
                          dropdownIndicator: (provided) => ({
                            ...provided,
                            bg: 'white',
                            px: 2,
                            cursor: 'inherit'
                          }),
                          container: (provided) => ({
                            ...provided,
                            backgroundColor: 'white'
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: 'none'
                          })
                        }}
                        placeholder="Delay units"
                        selectedOptionColor="cyan"
                        value={
                          formData.delayUnits
                            ? {
                                key: formData.delayUnits,
                                label: capitalizeFirstLetter(
                                  formData.delayUnits
                                ),
                                value: formData.delayUnits
                              }
                            : null
                        }
                        options={[
                          {
                            key: 'minute',
                            label: 'Minutes',
                            value: 'minute'
                          },
                          {
                            key: 'hour',
                            label: 'Hours',
                            value: 'hour'
                          },
                          {
                            key: 'day',
                            label: 'Days',
                            value: 'day'
                          }
                        ]}
                        onChange={(e) => {
                          console.log(e);
                          return setFormData({
                            ...formData,
                            delayUnits: e.value
                          });
                        }}
                      />
                    </GridItem>
                  </Grid>
                </FormControl>
              </Box>
              <Box p="5" borderWidth="1px" bg="white" mt="5">
                <FormControl>
                  <FormLabel>Attach Image or GIF</FormLabel>
                  <FormHelperText>
                    MMS is 1600 characters but available for the USA only.
                    Character limit for a single message is 160 characters. When
                    using Emojis or Special characters a single message length
                    goes down to 70 characters.
                  </FormHelperText>
                  <ImageUpload />
                </FormControl>
              </Box>
              <Box p="5" mt="5" borderWidth="1px" bg="white">
                <FormControl>
                  <FormLabel>Text Message</FormLabel>
                  <FormHelperText>
                    Customize your text message here. This is the text that your
                    SMS subscribers will receive once your text marketing
                    campaign is sent.
                  </FormHelperText>
                  <Stack p="20px 0" spacing={4} direction="row" align="center">
                    <Wrap>
                      {textVars.map((textVar, i) => (
                        <WrapItem key={`handlebar-${i}`}>
                          <Button
                            size="sm"
                            colorScheme="cyan"
                            variant="outline"
                            type="button"
                            // onClick={() => {
                            //   console.log(' CLICKED: ', textVar, cursorState);
                            // }}
                            onClick={() => insertText(textVar)}
                          >
                            {textVar}
                          </Button>
                        </WrapItem>
                      ))}
                    </Wrap>
                  </Stack>
                  <Textarea
                    bg="white"
                    rows="8"
                    value={formMessageValue}
                    focusBorderColor="cyan"
                    onChange={(e) =>
                      setFormData({ ...formData, message: e.target.value })
                    }
                    ref={textAreaRef}
                  />
                </FormControl>
              </Box>
              <Button
                color="white"
                colorScheme="cyan"
                width="full"
                mt={8}
                type="submit"
                isLoading={formData.isLoading}
              >
                Save
              </Button>
            </form>
          </Box>
        </Box>
        <Box minWidth="400px" p="4">
          <Container
            p="14px"
            bg="black"
            borderRadius="36px"
            height="480px"
            width="240px"
            border="2px solid #efefef"
          >
            <Box
              borderRadius="10px"
              h="100%"
              padding="4"
              bg="#F4F7FA"
              maxW="md"
              top="-14px"
            >
              <Box
                position="relative"
                top="-16px"
                h="16px"
                borderRadius="0 0 14px 14px"
                bg="black"
                w="120px"
                margin="auto"
              />
              {files.map((file, i) => (
                <div style={thumb} key={`file-${i}`}>
                  <div style={thumbInner}>
                    {/* {console.log('RENDER FILE: ', file)} */}
                    <img
                      alt="No"
                      src={file.preview}
                      style={img}
                      // Revoke data uri after image is loaded
                      onLoad={() => {
                        URL.revokeObjectURL(file.preview);
                      }}
                    />
                  </div>
                </div>
              ))}
              {formMessageValue && formMessageValue.length > 0 && (
                <Text
                  borderRadius="10px"
                  p="8px"
                  color="white"
                  bg="#147efb"
                  fontSize="xs"
                >
                  {formMessageValue}
                </Text>
              )}
            </Box>
          </Container>
        </Box>
      </Flex>
    </React.Suspense>
  );
}
