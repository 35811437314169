import { Box, Center, Heading, Spinner, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import { AccessToken, Account } from '../../models';
import Parse from '../../parse';
import useInterval from '../../hooks/useInterval';

import './Fade.css';

const authProvider = {
  authenticate: (options) => {
    // Some code to get retrieve authData
    // If retrieved valid authData, call success function with the data
    console.log('Starting auth with options ');
    console.log('options');
    console.log(options);
    options.success(this, {
      id: 1234
    });
    // You can also handle error
    // options.error(this, {});
  },
  deauthenticate: () => {
    console.log('starting to DEEauthenticate');
    return null;
  },
  getAuthType: () => {
    console.log('Getting auth type');
    return 'shopify';
  },
  restoreAuthentication(data) {
    console.log('restoring authentication', data);
    return true;
  }
};

Parse.User._registerAuthenticationProvider(authProvider);

async function login(id, accessToken) {
  console.log('running logins');

  // const user = new Parse.User();

  try {
    const user = await Parse.User.logInWith('shopify', {
      authData: {
        id,
        accessToken
      }
    });
    console.log(user);
    // const resp = await user.linkWith(authProvider, {
    //   authData: {
    //     id,
    //     accessToken
    //   }
    // });

    console.log('User: ', user);
    console.log(user.toJSON());

    if (!user.get('account')) {
    }
    return user;
  } catch (error) {
    console.log(error);
  }
}

const textValues = [
  {
    header: 'Open Rates 🚀',
    stat: '98%',
    message:
      'Statistics show SMS open rates are as high as 98%, and that’s higher than any other marketing channel.'
  },
  {
    header: 'Lightning Fast Delivery ⚡️',
    // stat: '98%',
    message:
      'With immediate message delivery, you can send time-sensitive messages like flash sales and event-related promotions.'
  },
  {
    header: 'Higher Response Rates',
    // stat: '98%',
    message:
      'The average response rate for SMS marketing is 45%, which is significantly higher than most other forms of marketing (i.e. email)'
  },
  {
    header: 'Prefer Receiving Texts',
    stat: '70%',
    message:
      'For many customers, receiving texts is their preferred way of communicating. And 70% of customers say that SMS marketing is a good way for businesses to get their attention.'
  }
];

function MessageCard() {
  const [textStateIndex, setTextStateIndex] = useState(0);

  const [fadeProp, setFadeProp] = useState({
    fade: 'fade-in'
  });

  const [delay, setDelay] = useState(7000);
  const currentText = textValues[textStateIndex] || textValues[0];

  console.log('RENDING WITH INTERVAL : ');

  useInterval(() => {
    // Your custom logic here
    if (textStateIndex >= textValues.length) {
      setDelay(null);
      return;
    }
    if (delay === 7000) {
      setDelay(1000);
      setFadeProp({
        fade: 'fade-out'
      });
    } else {
      setTextStateIndex(textStateIndex + 1);
      setDelay(7000);
      setFadeProp({
        fade: 'fade-in'
      });
    }
  }, delay);

  return (
    <Center>
      <Box
        p="40px"
        // color="white"
        mt="4"
        bg="white"
        rounded="md"
        shadow="md"
        maxW={400}
        className={fadeProp.fade}
      >
        <Text fontWeight="500" fontSize="2xl">
          {currentText.header}
        </Text>
        {currentText.stat && (
          <Text color={'green'} fontSize="5xl" fontWeight="900">
            {currentText.stat}
          </Text>
        )}
        <Stack
          direction={{ base: 'column', md: 'row' }}
          textAlign="center"
          justify="center"
          spacing={{ base: 4, lg: 10 }}
          py={0}
        >
          <Box py={4} px={2}>
            <Box fontSize={'sm'}>{currentText.message}</Box>
          </Box>
        </Stack>
      </Box>
    </Center>
  );
}

export default function Auth() {
  const [currentUser, setCurrentUser] = useState('');
  const [searchParams] = useSearchParams();
  console.log('search params ', searchParams);

  // const source = searchParams.get('source');
  // const state = searchParams.get('state');
  async function handleAuth(id) {
    await Parse.User.logOut();
    console.log(id);
    const query = new Parse.Query(AccessToken);
    const accessToken = await query.get(id);

    if (!accessToken) {
      // handle error
      // TODO: Handle this scenario
      console.error('No token');
    }
    console.log(accessToken.get('accessToken'));
    const user = await login(
      accessToken.get('sourceId'),
      accessToken.get('accessToken')
    );
    if (!user.get('account')) {
      const result = await Parse.Cloud.run('createAccount', {
        shop: accessToken.get('sourceId'),
        source: accessToken.get('source'),
        accessToken: accessToken.get('accessToken')
      });
      console.log('Result of creating the account: ', result);
      const account = await Account.createWithoutData(result.accountId).fetch();
      console.log('We have the account: ', account);
      user.set('account', account);
      console.log('Saving the user: ', user);
      await user.save();
    } else if (user.get('account')) {
      const account = user.get('account');
      await account.save({
        accessToken: accessToken.get('accessToken')
      });
    }
    console.log('setting the current user');
    setCurrentUser(user);
  }
  const id = searchParams.get('id');

  // http://localhost:3000/auth?source=shopify&state=23323223r&id=fZKKCrzCsB
  // handleAuth(id);
  // const currentUser = Parse.User.current();

  useEffect(() => {
    if (id) {
      handleAuth(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();

  if (currentUser) {
    console.log('WE HAVE A CURRENT USER');
    // return <div>Navigate</div>;

    // TODO: testing
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return (
    <Navbar loading={true}>
      <Stack
        as={Box}
        textAlign={'center'}
        spacing={{ base: 4, md: 8 }}
        py={{ base: 20, md: 14 }}
      >
        <Center>
          <Spinner color="cyan.400" speed="0.9s" size={'lg'} />
        </Center>
        <Heading>Setting up your account</Heading>
        <MessageCard />
      </Stack>
    </Navbar>
  );
}
