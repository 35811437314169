import Parse from '../parse';

class Product extends Parse.Object {
  constructor(attributes) {
    // Pass the ClassName to the Parse.Object constructor
    super('Product', attributes);
    // All other initialization
  }
}

export default Product;
