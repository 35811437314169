import Parse from 'parse';

Parse.initialize('flynncap');
//javascriptKey is required only if you have it on server.

const host = process.env.REACT_APP_HOST_API || 'https://bump.herokuapp.com';

Parse.serverURL = `${host}/parse`;

export default Parse;
