import Parse from '../parse';

class Order extends Parse.Object {
  constructor(attributes) {
    // Pass the ClassName to the Parse.Object constructor
    super('Order', attributes);
    // All other initialization
  }
}

export default Order;
