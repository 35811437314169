import Parse from '../parse';

export const TextMessageSource = {
  automation: 'automation',
  campaign: 'campaign',
  manual: 'manual'
};

class TextMessage extends Parse.Object {
  constructor(attributes) {
    // Pass the ClassName to the Parse.Object constructor
    super('TextMessage', attributes);
  }

  static async getTextMessages(conversation, skip) {
    console.log(conversation);
    const query = new Parse.Query(TextMessage);
    query.equalTo('conversation', conversation);
    query.limit(100);
    if (skip) {
      query.skip(skip);
    }
    query.descending('createdAt');
    return (await query.find()).reverse();
  }
}

export default TextMessage;
