import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Account } from '../../models';
import Parse from '../../parse';

export default function ShopifyStoreLink() {
  const [searchParams] = useSearchParams();
  const shop = searchParams.get('shop');
  const location = useLocation();

  async function checkAccount(shop) {
    const currentUser = Parse.User.current();
    if (!currentUser) {
      // TODO: handle redirect for store login
      console.log('NO CURRENT USER!!!!!!');
    }
    const query = new Parse.Query(Account);
    query.equalTo('shop', shop);
    query.equalTo('type', 'shopify');
    let account;
    try {
      account = await query.first();
    } catch (error) {
      if (error.code === 209) {
        Parse.User.logOut();
        // Window location
        account = await query.first();
      }
    }

    // TODO: Match current user to account
    if (account) {
      // TODO: Handle redirect to main admin page
      console.log(
        'WE have an account and user and they match - redirect to the main page'
      );
    }
    // Otherwise we should call the surver
    // window.location.href = `${process.env.HOST}/auth?shop=${shop}`;
    console.log(process.env);
    console.log(location);
    const url = `${process.env.REACT_APP_HOST_API}/auth${location.search}}`;
    console.log('new url ', url);
    // navigate(url, { replace: true });
    window.location.href = url;
  }
  checkAccount(shop);
  console.log('RENDER THISSS ');
  return <div>Loading</div>;
}
