import Parse from '../parse';

class Conversation extends Parse.Object {
  constructor(attributes) {
    super('Conversation', attributes);
  }

  static async fetchConversations(account) {
    const query = new Parse.Query(Conversation);
    query.equalTo('account', account);
    query.equalTo('active', true);
    query.include('lastMessage');
    query.include('customer');
    query.descending('updatedAt');
    const conversations = await query.find();
    conversations.forEach((convo) =>
      console.log('CONVO TO JSON: ', convo.toJSON())
    );
    const convo = await conversations[0].fetchWithInclude([
      'customer',
      'lastMessage'
    ]);
    console.log('CONCON> ', convo.toJSON());
    console.log('Conversations From Loading ', conversations);
    return conversations;
  }
}

export default Conversation;
