import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Parse from '../parse';

export default function Protected({ children }) {
  const currentUser = Parse.User.current();
  console.log(currentUser);
  console.log(children);
  const location = useLocation();

  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;

  // return (
  //   <Route
  //     {...rest}
  //     render={() => {
  //       return children;
  //     }}
  //     // render={(props) =>
  //     //   currentUser && currentUser.id ? (
  //     //     { children }
  //     //   ) : (

  //     //   )
  //   />
  // );
}
