import Parse from '../parse';

class Media extends Parse.Object {
  constructor(attributes) {
    // Pass the ClassName to the Parse.Object constructor
    super('Media', attributes);
    // All other initialization
  }
}

export default Media;
