import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { formatDistance } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Center,
  Container,
  Heading,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react';
import { AutoSizer, List } from 'react-virtualized';

import Parse from '../../parse';
import {
  conversationsState,
  conversationsValue,
  selectedConversationState
} from '../../state';
import { dataState } from '../../utils';
import models from '../../models';

export default function Inbox() {
  const setConversations = useSetRecoilState(conversationsState);
  const conversations = useRecoilValue(conversationsValue);
  console.log('STATE: ', conversations);

  const [selectedConversation, setSelectedConversation] = useRecoilState(
    selectedConversationState
  );
  const [listHeight] = useState(400);
  const [listRowHeight] = useState(80);
  const [overscanRowCount] = useState(10);
  const [scrollToIndex] = useState(undefined);

  let selected = selectedConversation;
  if (conversations.data.length > 0 && !selectedConversation) {
    selected = conversations.data[0].id;
  }
  console.log('SELECTED: ', selected, ' reselected:: ', selectedConversation);
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchConversations() {
      if (conversations.state === dataState.initial) {
        setConversations({ ...conversations, state: dataState.loading });
      }
      try {
        const results = await models.Conversation.fetchConversations(
          models.Account.currentAccount()
        );
        results.forEach((result) => console.log(result.toJSON()));
        console.log('conversations: ', results);
        setConversations({ data: results, state: dataState.loaded });

        if (results.length > 1) {
          const result = [...results].sort((a, b) => {
            return b.updatedAt.getTime() - a.updatedAt.getTime();
          });
          setSelectedConversation(result[0].id);
        } else if (results.length === 1) {
          setSelectedConversation(results[0].id);
        }
      } catch (error) {
        console.log(typeof error);
        console.log(Object.keys(error));
        console.log(error.code);
        if (error.code === 209) {
          Parse.User.logOut();
          // Window location
          navigate('/login');
        }
        setConversations({ ...conversations, state: dataState.error });
      }
    }
    fetchConversations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function _getDatum(index) {
    console.log('Getting data for: ', conversations);
    return conversations.data[index];
  }

  function _noRowsRenderer() {
    if (conversations.state === dataState.loading) {
      return (
        <Center pt={'14'} color="cyan.200">
          <Spinner size={'xl'} />
        </Center>
      );
    }
    return (
      <Container>
        <Stack align={'center'} pt="14">
          <Heading as="h4" size="md">
            There's nothing here
          </Heading>
          <Text fontSize="sm">You don't have any conversations yet</Text>
        </Stack>
      </Container>
    );
  }
  function _rowRenderer({ index, isScrolling, key, style }) {
    const conversation = _getDatum(index);
    // console.log('Conversation: ', conversation);
    // console.log('Stles: ', style);
    console.log(conversation.toJSON());
    if (!conversation) {
      console.log(
        'WE DONT HAVE A CONVO FOR: ',
        index,
        ' CONVO: ',
        conversation
      );
    }
    const customer = conversation.get('customer');
    console.log('CUSTOMER: ', customer);
    const displayName = customer.get('displayName');
    const phone = conversation.get('customerPhone');
    const lastMessage = conversation.get('lastMessage');
    const lastMessageText = lastMessage.get('text');
    const lastMessageDate = formatDistance(
      lastMessage.get('createdAt'),
      new Date(),
      {
        addSuffix: true
      }
    );
    const background = selected === conversation.id ? 'cyan.400' : 'white';
    const color = selected === conversation.id ? 'white' : 'cyan.400';
    console.log(
      'Setting BG AND COLOR: ',
      selected,
      ' convo: ',
      conversation.id
    );
    return (
      //   <Stack key={key} spacing={0} align={'center'} mb={5}>
      <Box
        style={style}
        key={key}
        bg={background}
        w="100%"
        p={4}
        color={color}
        onClick={() => setSelectedConversation(conversation.id)}
        cursor="pointer"
        _hover={{
          background: 'cyan.600',
          color: 'white'
        }}
        _active={{
          background: 'cyan.700',
          color: 'white'
        }}
      >
        <Heading as="h4" size="md">
          {displayName}
        </Heading>
        <Text noOfLines={1} fontSize="xs">
          {lastMessageText}
        </Text>
        <Text fontSize="sm">
          {phone} • {lastMessageDate}
        </Text>
      </Box>
      // <div key={key} style={style}>
      //   <Heading fontSize={'large'} fontWeight={500} fontFamily={'body'}>
      //     John Doe
      //   </Heading>
      //   <Text color={'gray.600'}>{datum}</Text>
      // </div>
    );
  }

  return (
    <div>
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            ref="List"
            height={listHeight}
            overscanRowCount={overscanRowCount}
            noRowsRenderer={_noRowsRenderer}
            rowCount={conversations.data.length}
            rowHeight={listRowHeight}
            rowRenderer={_rowRenderer}
            scrollToIndex={scrollToIndex}
            width={width}
          />
        )}
      </AutoSizer>
    </div>
  );
}
