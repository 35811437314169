import Parse from '../parse';

class Customer extends Parse.Object {
  constructor(attributes) {
    // Pass the ClassName to the Parse.Object constructor
    super('Customer', attributes);
    // All other initialization
  }

  static async fetchSubscribers(account) {
    const query = new Parse.Query(Customer);
    query.equalTo('account', account);
    query.equalTo('active', true);
    return query.find();
  }

  static async findCustomers(account, searchPhrase) {
    const query = new Parse.Query(Customer);
    query.equalTo('account', account);
    query.equalTo('active', true);
    if (searchPhrase) {
      query.fullText('search', searchPhrase);
      query.ascending('$score');
      query.select('$score');
    }
    return query.find();
  }
}

export default Customer;
