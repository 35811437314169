import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Link, useNavigate } from 'react-router-dom';

import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';

import {
  Button,
  Container,
  Flex,
  Heading,
  Spacer,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';

import Parse from '../../parse';

import { automationsState } from '../../state';
import { Automation } from '../../models';
import replaceItemAtIndex from '../../utils/replaceItemAtIndex';
import { dataState } from '../../utils';
import SkeletonTableBody from '../../components/SkeletonTableBody';

function ToggleAutomation({ onToggle, enabled }) {
  // const onChange = (e, d) => {
  //   console.log(e.target.checked);
  //   // setInputValue(value);
  //   // Simple syntax to create a new subclass of Parse.Object.

  //   console.log(inputValue);

  // };
  console.log(onToggle);
  return (
    <Switch
      isChecked={enabled}
      onChange={(e) => {
        console.log(e.target.checked);
        onToggle(e.target.checked);
      }}
    />
  );
}

export default function Automations() {
  // const automations = useRecoilValue(automationsState);

  const [automations, setAutomations] = useRecoilState(automationsState);
  const currentUser = Parse.User.current();

  async function deleteAutomation(automationId) {
    console.log(automationId);
    const automation = Automation.createWithoutData(automationId);
    automation.set('active', false);
    await automation.save();
    console.log('Automation saved');
    console.log(automation);
    setAutomations((oldAutomations) => {
      console.log('Old automations length: ', oldAutomations.length);
      let newAutomations = [];
      oldAutomations.data.forEach((auto) => {
        console.log(auto.id);
        console.log(automation.id);
        if (automationId !== auto.id) {
          newAutomations.push(auto);
        }
      });
      console.log('New Automations', newAutomations.length);
      return { ...automations, data: newAutomations };
    });
  }

  async function removeAutomation(automationId) {
    console.log('Remove automation', automationId);
    await deleteAutomation(automationId);
  }

  async function toggleAutomation(checked, index, automation) {
    console.log(checked, index, automation);
    automation.set('enabled', checked);
    let newList = replaceItemAtIndex(automations.data, index, automation);
    setAutomations({ ...automations, data: newList });
    await automation.save();
    newList = replaceItemAtIndex(automations.data, index, automation);
    setAutomations({ ...automations, data: newList });
  }

  useEffect(() => {
    console.log('fetching automations: ', automations);

    async function fetchAutomations() {
      if (automations.state === 'initial') {
        setAutomations({ ...automations, state: dataState.loading });
      }
      try {
        const result = await Automation.fetchAutomations(
          currentUser.get('account')
        );
        setAutomations({ data: result, state: dataState.loaded });
      } catch (error) {
        if (error.code === 209) {
          Parse.User.logOut();
          // Window location
          navigate('/login');
        } else {
          setAutomations({ ...automations, state: dataState.error });
        }
      }
    }
    fetchAutomations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigate = useNavigate();
  function AutomationTable() {
    if (automations.state === dataState.loaded && automations.data.length < 1) {
      return (
        <Container>
          <Stack align={'center'} p="10">
            <Heading as="h5" size="sm">
              There's nothing here
            </Heading>
            <Text fontSize="sm">You haven't created any automations</Text>
            <Button
              colorScheme="cyan"
              bg="cyan.400"
              color={'white'}
              onClick={() => navigate('/automations/new')}
            >
              Create Automation
            </Button>
          </Stack>
        </Container>
      );
    }
    return (
      <TableContainer
        borderRadius={'md'}
        backgroundColor={'white'}
        whiteSpace="inherit"
        p="10px"
      >
        <Table
          border="none"
          cellSpacing="0"
          cellPadding="0"
          size="sm"
          variant="simple"
        >
          <Thead>
            <Tr>
              <Th>Automation Name</Th>
              <Th>Sent</Th>
              <Th>Clicked</Th>
              <Th>Ordered</Th>
              <Th>Earned</Th>
              <Th>Enabled</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          {automations.state !== dataState.loaded && <SkeletonTableBody />}
          {automations.state === dataState.loaded && (
            <Tbody>
              {automations.data.map((automation, i) => {
                console.log('automation media: ', automation.get('mediar'));
                if (automation.get('mediar')) {
                  const media = automation.get('mediar')[0];
                  console.log(media.url());
                }
                return (
                  <Tr key={automation.id}>
                    <Td>{automation.get('label')}</Td>
                    <Td>0 0% Unsub.</Td>
                    <Td>0 0% CTR</Td>
                    <Td>0 0% Conv</Td>
                    <Td>$0.00</Td>
                    <Td>
                      <ToggleAutomation
                        onToggle={(checked) =>
                          toggleAutomation(checked, i, automation)
                        }
                        enabled={automation.get('enabled')}
                      />
                    </Td>
                    <Td>
                      <Stack spacing={4} direction="row" align="center">
                        <Link to={`/automations/edit/${automation.id}`}>
                          <AiOutlineEdit />
                        </Link>
                        {/* <Link to={`/automations/copy/${automation.id}`}>
                          <AiOutlineCopy />
                        </Link> */}
                        <Button onClick={() => removeAutomation(automation.id)}>
                          <AiOutlineDelete />
                        </Button>
                      </Stack>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
      </TableContainer>
    );
  }
  console.log('============ RUNNING CLOUD CODE');
  // U7DBbhzKGW
  // Parse.Cloud.run('buyCredits', { accountId: 'U7DBbhzKGW', amount: 100 });
  return (
    <Container backgroundColor={'white'} maxW="inherit">
      <Flex padding={'10px 0'}>
        <Spacer />
        <Button
          bg="cyan.400"
          color={'white'}
          colorScheme="cyan"
          onClick={() => navigate('/automations/new')}
        >
          Create Automation
        </Button>
      </Flex>

      <AutomationTable />
    </Container>
  );
}
