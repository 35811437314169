import React from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import Inbox from './Inbox';
import Chat from './Chat';

// import Inbox from './Inbox';
// import Chat from './Chat';

export default function Conversations() {
  return (
    <Grid
      minH="400px"
      templateRows="repeat(1, 1fr)"
      templateColumns="repeat(4, 1fr)"
      gap={4}
    >
      <GridItem rowSpan={1} colSpan={1} bg="white">
        <Inbox />
      </GridItem>
      <GridItem colSpan={3}>
        <Chat />
      </GridItem>
    </Grid>
  );
}
