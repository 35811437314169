import {
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Flex,
  Box,
  useColorModeValue,
  CircularProgress
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import Parse from '../../parse';
import {
  analyticsState,
  analyticsValue,
  currentAccountValue
} from '../../state';
import analyticsUtils from '../../utils/analytics';

function Dashboard() {
  const [analytics, setAnalytics] = useRecoilState(analyticsState);
  const dashboardData = useRecoilValue(analyticsValue);
  const account = useRecoilValue(currentAccountValue);
  console.log('ACCOUNT: ', account);
  const navigate = useNavigate();

  // console.log('DASHBOARD DATA: ', dashboardData);

  useEffect(() => {
    async function getAnalytics() {
      console.log('Getting ANAL');
      try {
        console.log('TRYING ');
        const promises = [
          await analyticsUtils.totalDismissed(account),
          await analyticsUtils.textMessageStats(account),
          await analyticsUtils.sumOrders(account),
          await analyticsUtils.costMessages(account),
          await analyticsUtils.totalSubscribers(account),
          await analyticsUtils.totalUnsubscribers(account)
        ];
        await Promise.all(promises);
        console.log('GETTING ANALYTICS =================');
        const [
          dismissed,
          messageStats,
          revenue,
          cost,
          subscribers,
          unsubscribers
        ] = promises;

        let roi = Math.round((revenue - cost) / cost);
        if (roi <= 0) {
          roi = 0;
        }

        setAnalytics({
          ...analytics,
          totalMessages: messageStats ? messageStats.get('totalMessages') : 0,
          totalMessagesSent: messageStats
            ? messageStats.get('totalMessagesSent')
            : 0,
          totalAutomatedMessagesSent: messageStats.get(
            'totalAutomatedMessagesSent'
          ),
          messageStats,
          unsubscribers,
          dismissed,
          revenue,
          cost,
          subscribers,
          roi
        });

        return promises;
      } catch (error) {
        console.log('ERROR: ', error);
        if (error.code === 209) {
          Parse.User.logOut();
          // Window location
          navigate('/login');
        }
      }
    }
    getAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log('DASHBOARD DATA: ', dashboardData);
  // console.log(analytics);

  const data = [
    {
      stat: `${dashboardData.roi}%`,
      label: 'Acquisition Rate',
      key: 'roi'
    },

    {
      stat: `$${dashboardData.revenue}`,
      label: 'Revenue per Message',
      key: 'revenuePerMessage'
    },
    {
      stat: `$${dashboardData.revenue}`,
      label: 'Revenue Earned',
      key: 'revenue'
    },
    {
      stat: `${dashboardData.subscribers}`,
      label: 'Messages per Subscriber',
      key: 'messagesPerSubscriber'
    },
    {
      stat: `${dashboardData.subscribers}`,
      label: 'Unsubscribe Rate',
      key: 'unsubscribers'
    },
    {
      stat: `${dashboardData.subscribers}`,
      label: 'Total Subscribers',
      key: 'subscribers'
    }
  ];

  return (
    <SimpleGrid columns={3} spacing={20}>
      {data.map((stat) => (
        <StatCard key={stat.key} stat={stat} />
      ))}
    </SimpleGrid>
  );
}
const StatCard = ({ stat }) => {
  console.log('Stat: ', stat);
  let numLabel = stat.stat;
  if (stat.stat.indexOf('NaN') > -1) {
    console.log('NOT A NUMBER');
    numLabel = stat.stat.replace('NaN', '0');
  }
  return (
    <Flex p={50} w="full" alignItems="center" justifyContent="center">
      <Box
        bg={useColorModeValue('white', 'gray.800')}
        // maxW="lg"
        borderWidth="1px"
        rounded="md"
        shadow="sm"
        position="relative"
      >
        <Box p="6">
          <Flex mt="1" justifyContent="space-between" alignContent="center">
            <Box
              fontSize="2xl"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
            >
              <Stat textAlign={'center'}>
                <StatNumber>{numLabel}</StatNumber>
                <StatLabel>{stat.label}</StatLabel>
              </Stat>
              <CircularProgress color="green" value={30} size="60px" />
            </Box>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default Dashboard;
