import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import {
  AiOutlineDashboard,
  AiOutlineMessage,
  AiOutlineNodeIndex
  // AiOutlineNotification,
  // AiOutlinePieChart,
  // AiOutlineTeam,
  // AiOutlineLineChart
} from 'react-icons/ai';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Stack,
  Heading,
  Skeleton,
  ListItem,
  List
} from '@chakra-ui/react';

import { FiMenu } from 'react-icons/fi';
import { MdOfflineBolt } from 'react-icons/md';
import { IconContext } from 'react-icons';

const LinkItems = [
  { name: 'Dashboard', icon: AiOutlineDashboard, href: '/' },
  { name: 'Conversations', icon: AiOutlineMessage, href: '/conversations' },
  { name: 'Automations', icon: AiOutlineNodeIndex, href: '/automations' }
  // { name: 'Campaigns', icon: AiOutlineNotification, href: '/campaigns' },
  // { name: 'Subscribers', icon: AiOutlineTeam, href: '/subscribers' },
  // { name: 'Segments', icon: AiOutlinePieChart, href: '/segments' },
  // { name: 'Insights', icon: AiOutlineLineChart, href: '/insights' }
];

export default function SimpleSidebar({ loading, children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
        loading={loading}
      />

      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
        <Outlet />
      </Box>
    </Box>
  );
}

const SidebarContent = ({ loading, onClose, ...rest }) => {
  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" justifyContent="space-between">
        <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
          <Stack direction={'row'} align={'center'}>
            <Flex
              w={8}
              h={8}
              align={'center'}
              justify={'center'}
              rounded={'full'}
            >
              <IconContext.Provider value={{ color: '#0BC5EA', size: '2.4em' }}>
                <div>
                  <MdOfflineBolt />
                </div>
              </IconContext.Provider>
            </Flex>
            <Heading as="h4" size="lg">
              TxtBump
            </Heading>
          </Stack>
        </Flex>

        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {loading ? (
        <List>
          <ListItem padding={'16px'} margin="0 16px">
            <Skeleton height="24px" />
          </ListItem>
          <ListItem padding={'16px'} margin="0 16px">
            <Skeleton height="24px" />
          </ListItem>
          <ListItem padding={'16px'} margin="0 16px">
            <Skeleton height="24px" />
          </ListItem>
        </List>
      ) : (
        LinkItems.map((link) => (
          <NavItem key={link.name} icon={link.icon} href={link.href}>
            {link.name}
          </NavItem>
        ))
      )}
    </Box>
  );
};

const NavItem = ({ icon, href, children, ...rest }) => {
  const location = useLocation();
  let match = false;
  if (href === '/') {
    // console.log(href);
    if (href === location.pathname) {
      match = true;
    }
  } else if (location.pathname.indexOf(href) >= 0) {
    match = true;
  }

  let bg = 'inherit';
  let color = 'inherit';
  if (match) {
    bg = 'cyan.400';
    color = 'white';
  }
  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={href}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white'
        }}
        bg={bg}
        color={color}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white'
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        TxtBump
      </Text>
    </Flex>
  );
};
