import _ from 'lodash';

import Parse from '../parse';
import Models from '../models';
const Public = {
  textMessageStats: async (account) => {
    // console.log(account);
    return Models.TextMessageStats.fetchTextMessageStats(account);
  },

  totalDismissed: async (account) => {
    // console.log(account);
    const query = new Parse.Query(Models.DismissedPopup);
    query.equalTo('account', account);
    const result = await query.first();
    let totalDismissed = result ? result.get('totalDismissed') : 0;
    return totalDismissed;
  },

  totalSubscribers: async (account) => {
    const query = new Parse.Query(Models.Customer);
    query.equalTo('account', account);
    query.equalTo('smsMarketingConsent.marketingState', 'SUBSCRIBED');
    query.equalTo('active', true);
    const result = await query.findAll();
    console.log('RESULT: ', result);
    const totalSubscribers = result ? result.length : 0;
    return totalSubscribers;
  },

  totalUnsubscribers: async (account) => {
    const query = new Parse.Query(Models.Customer);
    query.equalTo('account', account);
    query.equalTo('smsMarketingConsent.marketingState', 'UNSUBSCRIBED');
    query.equalTo('active', true);
    const result = await query.findAll();
    const totalUnSubscribers = result ? result.length : 0;
    return totalUnSubscribers;
  },

  costMessages: async (account) => {
    const query = new Parse.Query(Models.TextMessage);
    query.equalTo('account', account);
    // TODO: need a to parse rate and who sent it?
    const results = await query.findAll();
    console.log('Results: ', results);
    const total = results.length;
    console.log('TOTAL: ', total);
    const value = Number.parseFloat(total) * 0.03;
    return value;
    // const total = _.reduce(
    //   results,
    //   function (sum, n) {
    //     return sum + Number.parseFloat(n.get('totalPriceUsd'));
    //   },
    //   0
    // );
  },

  sumOrders: async (account) => {
    // console.log(account);
    const query = new Parse.Query(Models.Order);
    query.equalTo('account', account);
    query.exists('automationJob');
    const results = await query.findAll();
    console.log('Results sum orders: ', results);
    const total = _.reduce(
      results,
      function (sum, n) {
        return sum + Number.parseFloat(n.get('totalPriceUsd'));
      },
      0
    );
    // console.log('TOTAL: ', total);
    return total;
  }
};

export default Public;
