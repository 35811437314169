import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import {
  Button,
  Container,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import { Account, Customer } from '../../models';
import { customersState } from '../../state';

export default function Subscribers() {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState('');
  const [customers, setCustomers] = useRecoilState(customersState);
  const account = Account.currentAccount();
  useEffect(() => {
    async function fetchSubscribers() {
      const customers = await Customer.fetchSubscribers(account);
      setCustomers(customers);
    }
    fetchSubscribers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log('RUNNING ');
  console.log(customers);

  async function findCustomers(searchPhrase) {
    setLoading(true);
    const customers = await Customer.findCustomers(account, searchPhrase);
    setCustomers(customers);
    setLoading(false);
  }

  return (
    <Container maxW="inherit">
      <Container mb="10" p="8" backgroundColor={'white'} maxW="inherit">
        <InputGroup size="md">
          <Input
            pr="4.5rem"
            type="text"
            placeholder="Search by name or phone number"
            onKeyUp={(e) => findCustomers(e.target.value)}
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm">
              Search
            </Button>
          </InputRightElement>
        </InputGroup>
      </Container>

      <Container backgroundColor={'white'} maxW="inherit">
        <TableContainer>
          <Table variant="simple" _loading={'&[data-loading=true]'}>
            <Thead>
              <Tr>
                <Th>Phone</Th>
                <Th>Name</Th>
                <Th>Date Joined</Th>
                <Th>Subscribed</Th>
              </Tr>
            </Thead>
            <Tbody>
              {customers.map((customer) => (
                <Tr key={customer.id}>
                  <Td>{customer.get('phone')}</Td>
                  <Td>{customer.get('displayName')}</Td>
                  <Td>{customer.createdAt.toString()}</Td>
                  <Td>{customer.get('smsMarketingConsent').state}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </Container>
  );
}
