// import Shop from "shopify-api-node";

import Parse from '../parse';

class Checkout extends Parse.Object {
  constructor(attributes) {
    // Pass the ClassName to the Parse.Object constructor
    super('Checkout', attributes);
    // All other initialization
  }
}

export default Checkout;
