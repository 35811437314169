import { atom, selector } from 'recoil';
import Parse from '../parse';
import utils, { dataState, optionText } from '../utils';

export const analyticsState = atom({
  key: 'Analytics',
  default: {
    acquisitionRate: 0,
    revenue: 0,
    cost: 0,
    roi: 0,
    subscribers: 0,
    unsubscribers: 0,
    totalMessages: 0,
    totalMessagesSent: 0,
    totalAutomatedMessagesSent: 0,
    dismissed: 0
  }
});

export const analyticsValue = selector({
  key: 'AnalyticsValue',
  get: ({ get }) => {
    const analytics = get(analyticsState);
    console.log();
    return {
      ...analytics,
      roi:
        analytics.revenue - analytics.cost <= 0
          ? 0
          : Math.round((analytics.revenue - analytics.cost) / analytics.cost) *
            100,
      acquisitionRate:
        analytics.dismissed && analytics.subscribers
          ? analytics.subscribers /
            (analytics.dismissed + analytics.subscribers)
          : 0
    };
  }
});

export const currentUserState = atom({
  key: 'CurrentUser',
  default: Parse.User.current()
});

export const currentAccountValue = selector({
  key: 'CurrentAccount',
  get: ({ get }) => {
    const user = get(currentUserState);
    if (user) {
      return user.get('account');
    }
    return null;
  }
});

export const accountState = atom({
  key: 'Account',
  default: null
});

export const automationsState = atom({
  key: 'Automations',
  default: {
    state: dataState.initial,
    data: []
  }
});

export const selectedConversationValue = selector({
  key: 'SelectedConversationValue',
  get: ({ get }) => {
    const conversations = get(conversationsState);
    const selectedConversationId = get(selectedConversationState);
    console.log('=================CONVERSATION', conversations);
    console.log('SELECTED CONVERSATION', selectedConversationId);
    const result = conversations.data.find(
      (conversation) => conversation.id === selectedConversationId
    );
    console.log('RESULT: ', result);
    return result;
  }
});

export const selectedConversationState = atom({
  key: 'SelectedConversationState',
  default: null
});

export const conversationsState = atom({
  key: 'Conversations',
  default: { state: dataState.initial, data: [] }
});

export const customersState = atom({
  key: 'Customers',
  default: []
});
export const conversationsValue = selector({
  key: 'ConversationsValue',
  get: ({ get }) => {
    const conversations = get(conversationsState);
    if (conversations.data && conversations.data.length < 2) {
      console.log('Conversations: ', conversations);
      return conversations;
    }
    const result = [...conversations.data].sort((a, b) => {
      console.log('CREATED AT FOR A: ', a.updatedAt.getTime());
      console.log('CREATED AT FOR B: ', b.updatedAt.getTime());
      return b.updatedAt.getTime() - a.updatedAt.getTime();
    });
    console.log('RESULT ++++++ ', result);
    result.forEach((r) => {
      console.log('UPDATED AT ', r.updatedAt.getTime());
    });
    return { ...conversations, data: result };
  }
});

export const textMessagesState = atom({
  key: 'TextMessages',
  default: []
});

export const upsertAutomationFormState = atom({
  key: 'UpsertAutomationForm',
  default: {
    automationType: null,
    message: '',
    label: '',
    automationId: null,
    files: [],
    isLoading: false,
    upsertType: 'new',
    delayValue: '',
    delayUnits: null
  }
});

export const upsertAutomationFormMessageValue = selector({
  key: 'UpsertAutomationFormMessageValue',
  get: ({ get }) => {
    const formData = get(upsertAutomationFormState);
    let messageValue = formData.message;
    console.log('FORMDATA: ', formData);
    if (
      formData.automationType === utils.AutomationTypes.CheckoutStarted.value &&
      (!messageValue || messageValue.trim().length === 0)
    ) {
      console.log('Set the message Value!');
      messageValue = optionText.checkoutStarted;
    } else if (
      formData.automationType === utils.AutomationTypes.PlacedOrder.value &&
      (!messageValue || messageValue.trim().length === 0)
    ) {
      console.log('Set the message Value!');
      messageValue = optionText.placedOrder;
    } else if (
      formData.automationType === utils.AutomationTypes.CustomerWinback.value &&
      (!messageValue || messageValue.trim().length === 0)
    ) {
      console.log('Set the message Value!');
      messageValue = optionText.customerWinback;
    } else if (
      formData.automationType === utils.AutomationTypes.NewSubscriber.value &&
      (!messageValue || messageValue.trim().length === 0)
    ) {
      console.log('Set the message Value!');
      messageValue = optionText.newSubscriber;
    } else if (
      formData.automationType === utils.AutomationTypes.OrderFulfilled.value &&
      (!messageValue || messageValue.trim().length === 0)
    ) {
      console.log('Set the message Value!');
      messageValue = optionText.orderFulfilled;
    }
    console.log('New Message Value: ', messageValue.trim().length);
    return messageValue;
  }
});

export const upsertAutomationFormLabelValue = selector({
  key: 'UpsertAutomationFormLabelValue',
  get: ({ get }) => {
    const formData = get(upsertAutomationFormState);
    let automationLabel = formData.label;
    if (automationLabel.trim().length > 0) {
      console.log('AUTOMATION LABEL: ', automationLabel);
      return automationLabel;
    }
    console.log(' RUNNING SWITCH: ', automationLabel, formData.automationType);
    switch (formData.automationType) {
      case utils.AutomationTypes.CheckoutStarted.value:
        automationLabel = utils.AutomationTypes.CheckoutStarted.label;
        break;
      case utils.AutomationTypes.CustomerWinback.value:
        automationLabel = utils.AutomationTypes.CustomerWinback.label;
        break;
      case utils.AutomationTypes.NewSubscriber.value:
        automationLabel = utils.AutomationTypes.NewSubscriber.label;
        break;
      case utils.AutomationTypes.OrderFulfilled.value:
        automationLabel = utils.AutomationTypes.OrderFulfilled.label;
        break;
      case utils.AutomationTypes.PlacedOrder.value:
        automationLabel = utils.AutomationTypes.PlacedOrder.label;
        break;
      default:
        automationLabel = '';
    }
    console.log(' AFTER SWITCH LABEL ', automationLabel);
    return automationLabel;
  }
});

const Public = {
  automationsState
};
export default Public;
