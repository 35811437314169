import React, { useEffect, useState } from 'react';
import {
  Box,
  ButtonGroup,
  Container,
  Flex,
  Heading,
  Button,
  Input,
  Spacer,
  Text
} from '@chakra-ui/react';
import { formatDistance } from 'date-fns';
// import { AiOutlinePicture } from 'react-icons/ai';
import { BiPaperPlane } from 'react-icons/bi';
import { AutoSizer, List } from 'react-virtualized';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TextMessage } from '../../models';
import {
  conversationsState,
  selectedConversationValue,
  textMessagesState
} from '../../state';
import replaceItemAtIndex from '../../utils/replaceItemAtIndex';

const cache = new CellMeasurerCache({
  defaultHeight: 50,
  fixedWidth: true
});

function ChatInput(props) {
  const [textMessages, setTextMessages] = useRecoilState(textMessagesState);
  const selectedConversation = useRecoilValue(selectedConversationValue);
  const [conversations, setConversations] = useRecoilState(conversationsState);

  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState('');

  async function sendMessage() {
    if (!selectedConversation) {
      return;
    }
    setIsLoading(true);
    const textMessage = new TextMessage({
      text,
      customer: selectedConversation.get('customer'),
      to: selectedConversation.get('customerPhone'),
      source: 'conversation',
      account: selectedConversation.get('account'),
      from: selectedConversation.get('accountPhone'),
      active: true,
      fromAdmin: true,
      conversation: selectedConversation
    });
    const promises = [
      await textMessage.save(null, { context: { trigger: true } }),
      await selectedConversation.save({ lastMessage: textMessage })
    ];
    await Promise.all(promises);

    setText('');
    setTextMessages([...textMessages, textMessage]);
    const index = conversations.data.findIndex(
      (auto) => auto.id === selectedConversation.id
    );
    const newList = replaceItemAtIndex(
      conversations.data,
      index,
      selectedConversation
    );
    setConversations({ ...conversations, data: newList });

    setIsLoading(false);
  }

  return (
    <div>
      <Flex gap="2">
        <Box flex="1">
          <Input
            value={text}
            placeholder="Message text"
            gap="2"
            onChange={(e) => setText(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                // Do something
                sendMessage();
              }
            }}
            disabled={props.disabled}
          />
        </Box>
        <ButtonGroup gap="2">
          {/* <IconButton
            colorScheme="cyan"
            color={'white'}
            aria-label="Search database"
            icon={<AiOutlinePicture />}
          /> */}

          <Button
            rightIcon={<BiPaperPlane />}
            isLoading={isLoading}
            colorScheme="cyan"
            color={'white'}
            aria-label="Send message"
            icon={<BiPaperPlane />}
            onClick={sendMessage}
            disabled={props.disabled}
          >
            Send
          </Button>
        </ButtonGroup>
      </Flex>
    </div>
  );
}

export default function Chat() {
  const listHeight = 400;
  const overscanRowCount = 10;
  const scrollToIndex = 100;
  const [textMessages, setTextMessages] = useRecoilState(textMessagesState);
  const selectedConversation = useRecoilValue(selectedConversationValue);

  useEffect(() => {
    // console.log('Get messages with ', selectedConversation);
    async function getMessages(selectedConversation) {
      // console.log('RE GET TEXT MESSAGES ++++++++++++++++');
      // console.log(selectedConversation);
      if (!selectedConversation) {
        return;
      }
      console.log('Get text messages with convo: ', selectedConversation);
      const result = await TextMessage.getTextMessages(selectedConversation);
      setTextMessages(result);
    }
    getMessages(selectedConversation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversation]);

  function _getDatum(index) {
    return textMessages[index];
  }

  function _noRowsRenderer() {
    return <div />;
  }

  function _rowRenderer({ index, key, parent, style }) {
    const datum = _getDatum(index);
    // console.log('Stle: ', style);
    // console.log(datum.toJSON());
    const lastMessageDate = formatDistance(datum.get('createdAt'), new Date(), {
      addSuffix: true
    });
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure, registerChild }) => (
          // 'style' attribute required to position cell (within parent List)

          <div ref={registerChild} style={style}>
            <Flex>
              {!datum.get('received') && (
                <>
                  <Spacer />
                  <Box
                    maxW={'500'}
                    borderRadius={'8'}
                    m="4"
                    marginBottom={2}
                    p="4"
                    bg="cyan.500"
                  >
                    <Text color={'white'}>{datum.get('text')}</Text>
                  </Box>
                </>
              )}
              {datum.get('received') && (
                <>
                  <Box
                    maxW={'200'}
                    borderRadius={'8'}
                    m="4"
                    marginBottom={2}
                    p="4"
                    bg="gray.600"
                  >
                    <Text color={'white'}>{datum.get('text')}</Text>
                  </Box>
                  <Spacer />
                </>
              )}
            </Flex>
            <Flex>
              {!datum.get('received') && (
                <>
                  <Spacer />

                  <Box m="4" marginTop={0}>
                    <Text fontSize="xs" color={'grey'}>
                      {lastMessageDate}
                    </Text>
                  </Box>
                </>
              )}
              {datum.get('received') && (
                <>
                  <Box m="4" marginTop={0}>
                    <Text fontSize="xs" color={'grey'}>
                      {lastMessageDate}
                    </Text>
                  </Box>
                  <Spacer />
                </>
              )}
            </Flex>

            {/* <Box
              style={style}
              key={key}
              bg="cyan.400"
              w="100%"
              p={4}
              color="white"
            > */}

            {/* </Box> */}
          </div>
        )}
      </CellMeasurer>
    );
  }
  // console.log(textMessages.length);

  let displayName;
  let displayNumber = '';
  if (selectedConversation) {
    console.log('select convo: ', selectedConversation.toJSON());
    const customer = selectedConversation.get('customer');
    displayName = customer.get('displayName');
    displayNumber = customer.get('phone');
    console.log('DISPLAY NAME: ', displayName);
    if (!displayName) {
      console.log('update DISPLAY NAMEEEEEEE');
      displayName = displayNumber;
      displayNumber = '';
    }
  }
  return (
    <Container
      bg="white"
      margin="inherit"
      maxW="inherit"
      paddingTop={'6'}
      paddingBottom={'6'}
    >
      <Heading as="h5" size="sm">
        {displayName}
      </Heading>
      <Text>{displayNumber}</Text>
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            ref="List"
            height={listHeight}
            overscanRowCount={overscanRowCount}
            noRowsRenderer={_noRowsRenderer}
            rowCount={textMessages.length}
            rowHeight={cache.rowHeight}
            rowRenderer={_rowRenderer}
            scrollToIndex={scrollToIndex}
            width={width}
            useDynamicRowHeight={true}
          />
        )}
      </AutoSizer>
      <ChatInput disabled={selectedConversation === undefined} />
    </Container>
  );
}
