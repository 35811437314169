import React from 'react';
// import React, { useEffect, useState } from 'react';
// import { useRecoilState } from 'recoil';

import {
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';

// import { Account, Customer } from '../../models';
// import { customersState } from '../../state';

const steps = [
  { label: 'Welcome' },
  { label: 'Compliance' },
  { label: 'Terms of Service' },
  { label: 'Privacy Policy' },
  { label: 'Design' },
  { label: 'Review' },
  { label: 'Dashboard' }
];

function Content({ index }) {
  console.log('index: ', index);
  if (index === 0) {
    return (
      <Container textAlign={'left'}>
        <Stack spacing={4}>
          <Heading>Welcome to txtbump!</Heading>
          <Text>
            The average txtbump customer generates 18% of their store's revenue
            with txtbump! Over the next 5 minutes, we'll walk you through set-up
            so you can do the same.
          </Text>
          <Text>
            In the background, we're syncing with your store to enable powerful
            segmentation based on your customers' order history
          </Text>
          <Image src="https://storage.googleapis.com/bump/500_92.jpg" />
        </Stack>
      </Container>
    );
  } else if (index === 1) {
    return (
      <Container textAlign={'left'}>
        <Stack spacing={4}>
          <Heading>txtbump keeps you TCPA compliance</Heading>
          <Heading>TCPA</Heading>
          <Text>
            U.S. Congress passed the Telephone Consumer Protection Act to
            control how companies can use text messaging services to reach out
            to their consumers. The TCPA’s regulation boils down to the
            following statement: Consumers have the right not to receive
            unsolicited marketing communications via SMS, and companies should
            not send consumers automated SMS marketing text messages unless they
            have obtained a consumer’s prior express written consent. Opt-in
            popups and forms inside Postscript (and through third-party app
            integrations) are built to compliantly collect express written
            consent. Consumers can unsubscribe from your SMS list at any time.
          </Text>
          <Text>
            l understand that per the TCPA I cannot send text messages to my
            previously-collected customer phone numbers. I understand that per
            the TCPA, I cannot send text messages to anyone who has not given me
            prior express written consent.
          </Text>
        </Stack>
      </Container>
    );
  } else if (index === 2) {
    return (
      <Container textAlign={'left'}>
        <Stack spacing={4}>
          <Heading> Update your Shop's Terms of Service</Heading>
          <Text>
            To ensure your Shop remains consistent with legal and carrier
            compliance requirements and policies, you must include the following
            information at the top of your Shop's Terms of Service.
          </Text>

          <Textarea>
            In addition, you agree to our Messaging Terms
            (https://terms.pscr.pt/legal/shop/flynns-widgets/terms_of_service)
            and Messaging Privacy Policy
            (https://terms.pscr.pt/legal/shop/flynns-widgets/privacy_policy).
          </Textarea>
        </Stack>
      </Container>
    );
  }
}

const VerticalSteps = () => {
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0
  });
  return (
    <>
      <Steps orientation="vertical" activeStep={activeStep}>
        {steps.map(({ label }, index) => (
          <Step width="100%" label={label} key={label}>
            <Content index={index} />
          </Step>
        ))}
      </Steps>
      {activeStep === steps.length ? (
        <Flex px={4} py={4} width="100%" flexDirection="column">
          <Heading fontSize="xl" textAlign="center">
            Woohoo! All steps completed!
          </Heading>
          <Button mx="auto" mt={6} size="sm" onClick={reset}>
            Reset
          </Button>
        </Flex>
      ) : (
        <Flex width="100%" justify="flex-end">
          <Button
            isDisabled={activeStep === 0}
            mr={4}
            onClick={prevStep}
            size="sm"
            variant="ghost"
          >
            Prev
          </Button>
          <Button size="sm" onClick={nextStep}>
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Flex>
      )}
    </>
  );
};

export default function Welcome() {
  return (
    <Container maxW="inherit">
      <VerticalSteps />
    </Container>
  );
}
