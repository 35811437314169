import React from 'react';
// import * as ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
// import './Script';
// import './postscript';
// import './sdk';`

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

import Navbar from './components/Navbar';
import Dashboard from './pages/dashboard/Dashboard';
import Conversations from './pages/conversations/Conversations';
import ShopifyStoreLink from './pages/shopifyStoreLink/ShopifyStoreLink';
import DebugObserver from './components/DebugObserver';
import Auth from './pages/auth/Auth';
import Welcome from './pages/welcome/Welcome';
import UpsertAutomation from './pages/automations/upsertAutomation/UpsertAutomation';
import Automations from './pages/automations/Automations';
import Subscribers from './pages/subscribers/Subscribers';
import Protected from './components/Protected';

const theme = extendTheme({
  components: {
    Steps
  }
});

function App() {
  console.log('LOAD APP!!!!!!!!!!!!!!!!!');
  return (
    <BrowserRouter>
      <RecoilRoot>
        <DebugObserver />
        <ChakraProvider theme={theme}>
          <Routes>
            <Route path="/shopify" element={<ShopifyStoreLink />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/" element={<Navbar />}>
              <Route
                path="/"
                element={
                  <Protected>
                    <Dashboard />
                  </Protected>
                }
              />
              <Route
                path="conversations"
                element={
                  <Protected>
                    <Conversations />
                  </Protected>
                }
              />
              <Route
                path="automations/new"
                element={
                  <Protected>
                    <UpsertAutomation />
                  </Protected>
                }
              />
              <Route
                path="automations/edit/:automationId"
                element={
                  <Protected>
                    <UpsertAutomation />
                  </Protected>
                }
              />
              <Route
                path="automations/copy/:automationId"
                element={
                  <Protected>
                    <UpsertAutomation />
                  </Protected>
                }
              />
              <Route
                path="automations"
                element={
                  <Protected>
                    <Automations />
                  </Protected>
                }
              />
              <Route
                path="campaigns"
                element={
                  <Protected>
                    <Automations />
                  </Protected>
                }
              />
              <Route path="subscribers" element={<Subscribers />} />
              <Route path="segments" element={<div>Segments</div>} />
              <Route path="insights" element={<div>insights</div>} />
            </Route>
          </Routes>
        </ChakraProvider>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
