import Parse from '../parse';

class TextMessageStats extends Parse.Object {
  constructor(attributes) {
    // Pass the ClassName to the Parse.Object constructor
    super('TextMessageStats', attributes);
  }

  static async fetchTextMessageStats(account) {
    const query = new Parse.Query(TextMessageStats);
    query.equalTo('account', account);
    return query.first();
  }
}

export default TextMessageStats;
