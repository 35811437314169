import Parse from '../parse';

import AccessToken from './AccessToken';
import Account from './Account';
import Activity from './Activity';
import Automation from './Automation';
import Cart from './Cart';
import Checkout from './Checkout';
import Conversation from './Conversation';
import Customer from './Customer';
import DismissedPopup from './DimissedPopup';
import Media from './Media';
import Order from './Order';
import Product from './Product';
import TextMessage from './TextMessage';
import TextMessageStats from './TextMessageStats';

Parse.Object.registerSubclass('AccessToken', AccessToken);
Parse.Object.registerSubclass('Account', Account);
Parse.Object.registerSubclass('Activity', Activity);
Parse.Object.registerSubclass('Automation', Automation);
Parse.Object.registerSubclass('Cart', Cart);
Parse.Object.registerSubclass('Checkout', Checkout);
Parse.Object.registerSubclass('Conversation', Conversation);
Parse.Object.registerSubclass('Customer', Customer);
Parse.Object.registerSubclass('DismissedPopup', DismissedPopup);
Parse.Object.registerSubclass('Media', Media);
Parse.Object.registerSubclass('Order', Order);
Parse.Object.registerSubclass('Product', Product);
Parse.Object.registerSubclass('TextMessage', TextMessage);
Parse.Object.registerSubclass('TextMessageStats', TextMessageStats);

const Models = {
  AccessToken,
  Account,
  Activity,
  Automation,
  Cart,
  Checkout,
  Conversation,
  Customer,
  DismissedPopup,
  Media,
  Order,
  Product,
  TextMessage,
  TextMessageStats
};

export {
  AccessToken,
  Account,
  Activity,
  Automation,
  Cart,
  Checkout,
  Conversation,
  Customer,
  DismissedPopup,
  Media,
  Order,
  Product,
  TextMessage,
  TextMessageStats
};
export default Models;
