import Parse from '../parse';

class Automation extends Parse.Object {
  constructor(attributes) {
    // Pass the ClassName to the Parse.Object constructor
    super('Automation', attributes);
    // All other initialization
  }

  static async fetchAutomations(account) {
    console.log('ACCOUNT: ', account);
    const query = new Parse.Query(Automation);
    query.equalTo('account', account);
    query.equalTo('active', true);
    const results = await query.find();
    return results;
  }
}

export default Automation;
