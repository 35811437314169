import Parse from '../parse';

class Cart extends Parse.Object {
  constructor(attributes) {
    // Pass the ClassName to the Parse.Object constructor
    super('Cart', attributes);
    // All other initialization
  }
}

export default Cart;
