import Parse from '../parse';

export class DismissedPopup extends Parse.Object {
  constructor(attributes) {
    // Pass the ClassName to the Parse.Object constructor
    super('DismissedPopup', attributes);
    // All other initialization
  }

  static async fetchOrCreateDismissedPopup(account) {
    const query = new Parse.Query(DismissedPopup);
    query.equalTo('account', account);
    let dismissedPopup = await query.first();
    if (!dismissedPopup) {
      dismissedPopup = new DismissedPopup({ account, totalDismissed: 0 });
    }
    dismissedPopup.increment('totalDismissed');
    await dismissedPopup.save();
    return dismissedPopup;
  }
}

export default DismissedPopup;
